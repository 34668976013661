body {
	margin: 0;
	font: 15px/20px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
